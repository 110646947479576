// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiReferenciaLanding: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS">
            <div className="_compact">
                <ApiLogo format={'all'} />
                <h2>Acerca del API para facturación</h2>
                <p className="intro">
                    <b>La interacción con el API es usando REST (GET / POST) y JSON.</b> <br />
                    Por esta razón, podrás usar nuestro API en cualquier dispositivo, leguaje o ambiente, desde Móvil, BackEnd, FrontEnd, etc. 
                </p>
                <p  className="intro">
                    <b>No tendrás que preocuparte con generar XMLs, OpenSSL o ningúna herramienta que no sea simplemente un REST Client en el lenguaje que desées.</b> Nostros realizamos todas
                    las operaciones necesarias como decriptar, encriptar, almacenar y generar los XMLs y PDFs. <br />
                </p>
                <p  className="intro">
                    Te proveemos de la información necesaria para generar la expansión que desées, nuestas facturas regresan en como un objecto con el XML, el UUID y una representación del CFDI en JSON dentro del mismo objeto.
                </p>
                <p  className="intro">
                    Para iniciar tu integración habrá sido necesario que hayas registrado tu cuenta de <a href="https://dashboard.fiscalpop.com">FiscalPOP API</a>. Se hará uso del MasterToken de tu cuenta de desarrollador para poder realizar las operaciones.
                </p>
                <GoToInstall href="/api/referencia/clientes-agregar" />
                <div className="sectionNote">
                    <p>
                        Tus cerificados y los de tus usuarios están seguros con nosotros ya que <b>no guardamos</b> estos archivos, solo obtenemos la
información que requerimos, la encriptamos y posteriormente los descartamos.
                    </p>
                </div>
            </div>
        </div>
    </Layout>
)

export default ApiReferenciaLanding
